import { connect } from 'react-redux';
import HomeComponent from './HomeComponent';
import { homeOperations, homeActions } from './redux';
import { onboardOperations, onboardActions } from '../onboard/redux';
import { loginOperations } from '../auth/login/redux';

const mapStateToProps = state => {
  return {
    homeState: state.home,
    loginState: state.login,
    onboardState: state.onboard,
    portalConfig: state.login.portalConfig
  };
};

const mapDispatchToProps = dispatch => {
  const getSessionData = (
    lastLogin,
    partnerList,
    sort,
    sortOrder,
    limit,
    offset,
    filters
  ) =>
    dispatch(
      homeOperations.getSessionJson(
        lastLogin,
        partnerList,
        sort,
        sortOrder,
        limit,
        offset,
        filters
      )
    );
  const getPatientsData = (
    partnerList,
    sort,
    sortOrder,
    limit,
    offset,
    filters
  ) =>
    dispatch(
      onboardOperations.getPatientsJson(
        partnerList,
        sort,
        sortOrder,
        limit,
        offset,
        filters
      )
    );
  const saveFilter = data => dispatch(loginOperations.saveFilter(data));
  const saveLogs = data => dispatch(loginOperations.saveLogs(data));
  const downloadCsv = data => dispatch(homeOperations.downloadCsv(data));

  const setFollowedby = value => dispatch(homeActions.setFollowedby(value));
  const setTotalPatients = () => dispatch(onboardActions.setTotalPatients());

  return {
    getSessionData,
    getPatientsData,
    saveFilter,
    saveLogs,
    downloadCsv,
    setFollowedby,
    setTotalPatients
  };
};

const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeComponent);

export default HomeContainer;
