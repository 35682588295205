import types from './types';

const INITIAL_STATE = {
  spinner: false,
  text: '',
  error: false,

  spinnerCode: false,
  codetext: '',
  codeerror: false,
  resetLink: null,
  codeValidated: false
};

const resetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_CHECK_EMAIL: {
      return {
        ...state,
        spinner: true,
        text: '',
        error: false
      };
    }

    case types.RESET_CHECK_EMAIL: {
      return INITIAL_STATE;
    }

    case types.REQUEST_CHECK_CODE: {
      return {
        ...state,
        spinnerCode: true,
        codetext: '',
        codeerror: false
      };
    }

    case types.RECEIVE_CHECK_EMAIL: {
      const { error, text } = action;
      return {
        ...state,
        spinner: false,
        text,
        error
      };
    }

    case types.RECEIVE_CHECK_CODE: {
      const { error, text, link, completed } = action;

      return {
        ...state,
        spinnerCode: false,
        codetext: text,
        codeerror: error,
        resetLink: link,
        codeValidated: completed
      };
    }

    default:
      return state;
  }
};

export default resetReducer;
