/* 
    This file contains string literals for your action types. 
    This provides an easy reference for the actions available. 
    These strings are exported as an object literal which can then be 
    imported into your reducers and action creators instead of hard-coding them. 
    Although maintaining a separate file containing just your action types is 
    optional, it is highly recommended.
*/

const REQUEST_CHECK_EMAIL = 'REQUEST_CHECK_EMAIL';
const RESET_CHECK_EMAIL = 'RESET_CHECK_EMAIL';
const RECEIVE_CHECK_EMAIL = 'RECEIVE_CHECK_EMAIL';

const REQUEST_CHECK_CODE = 'REQUEST_CHECK_CODE';
const RECEIVE_CHECK_CODE = 'RECEIVE_CHECK_CODE';

export default {
  REQUEST_CHECK_EMAIL,
  RESET_CHECK_EMAIL,
  REQUEST_CHECK_CODE,
  RECEIVE_CHECK_EMAIL,
  RECEIVE_CHECK_CODE
};
