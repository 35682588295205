import React from 'react';
import PropTypes from 'prop-types';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core';

function DeleteDialog({ close, text, deleteAction, loading }) {
  return (
    <div>
      <DialogTitle>Delete</DialogTitle>
      {loading ? (
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 20
          }}
        >
          <CircularProgress />
          <Typography style={{ marginTop: 10 }} color="inherit" variant="body1">
            Please wait while the system completes the patient deletion
          </Typography>
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <DialogContentText>{`${text}`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button onClick={deleteAction} color="primary">
              Continue
            </Button>
          </DialogActions>
        </>
      )}
    </div>
  );
}

DeleteDialog.propTypes = {
  close: PropTypes.func,
  text: PropTypes.string,
  deleteAction: PropTypes.func,
  loading: PropTypes.bool
};

export default DeleteDialog;
