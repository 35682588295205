import fetch from 'cross-fetch';
import { config } from '../../../../config/firebase';

import {
  requestCheckEmail,
  requestCheckCode,
  receiveCheckCode,
  receiveCheckEmail
} from './actions';

const checkPhoneNumber = data => {
  return dispatch => {
    dispatch(requestCheckEmail());
    const url = `https://us-central1-${config.projectId}.cloudfunctions.net/dashboardFunctions-checkPhoneNumber`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        // Examine and get  the text in the response
        response.json().then(res => {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ',
              response.status
            );
            dispatch(receiveCheckEmail(true, res.result));
          } else {
            dispatch(receiveCheckEmail(false, res.result));
          }
        });
      })
      .catch(err => {
        console.log('Error while fetching ', err);
        dispatch(
          receiveCheckEmail(true, 'Sorry, an error occurred. Try again later.')
        );
      });
  };
};

const validateConfirmationCode = data => {
  return dispatch => {
    dispatch(requestCheckCode());
    const url = `https://us-central1-${config.projectId}.cloudfunctions.net/dashboardFunctions-validateConfirmationCode`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        // Examine and get  the text in the response
        response.json().then(res => {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem with confirmation code process. Status Code: ',
              response.status
            );
            dispatch(receiveCheckCode(true, res.result, null, false));
          } else {
            dispatch(receiveCheckCode(false, res.result, res.link, true));
          }
        });
      })
      .catch(err => {
        console.log('Error while fetching fro code validation, ', err);
        dispatch(
          receiveCheckCode(
            true,
            'Sorry, an error occurred. Try again later.',
            null,
            false
          )
        );
      });
  };
};

export default {
  checkPhoneNumber,
  validateConfirmationCode
};
