import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'column'
  }
});

function DisclaimerComponent({ classes }) {
  return (
    <div className={classes.root}>
      <Link
        href="http://acm.mementodepot.org/pubs/books/3107990/3107990.3108004/3107990.3108004.pdf"
        // onClick={preventDefault}
        target="_blank"
        variant="body2"
      >
        http://acm.mementodepot.org/pubs/books/3107990/3107990.3108004/3107990.3108004.pdf
      </Link>
      <div style={{ margin: 10 }} />
      <Link
        href="https://www.aclweb.org/anthology/W17-3101"
        // onClick={preventDefault}
        target="_blank"
        variant="body2"
      >
        https://www.aclweb.org/anthology/W17-3101
      </Link>
      <div style={{ margin: 10 }} />
      <Link
        href="https://www.cambridge.org/core/services/aop-cambridge-core/content/view/32645FFCFD37C67DA62CA06DB66EB2F4/S1351324916000383a.pdf/natural_language_processing_in_mental_health_applications_using_nonclinical_texts.pdf"
        // onClick={preventDefault}
        target="_blank"
        variant="body2"
      >
        https://www.cambridge.org/core/services/aop-cambridge-core/content/view/32645FFCFD37C67DA62CA06DB66EB2F4/S1351324916000383a.pdf/natural_language_processing_in_mental_health_applications_using_nonclinical_texts.pdf
      </Link>
    </div>
  );
}

export default withStyles(styles)(DisclaimerComponent);
