/* 
    This file contains string literals for your action types. 
    This provides an easy reference for the actions available. 
    These strings are exported as an object literal which can then be 
    imported into your reducers and action creators instead of hard-coding them. 
    Although maintaining a separate file containing just your action types is 
    optional, it is highly recommended.
*/

const REQUEST_SESSION_JSON = 'REQUEST_SESSION_JSON';
const RECEIVE_SESSION_JSON = 'RECEIVE_SESSION_JSON';

const REQUEST_PATIENT_JSON = 'REQUEST_PATIENT_JSON';
const RECEIVE_PATIENT_JSON = 'RECEIVE_PATIENT_JSON';

const REQUEST_DOWNLOAD_CSV = 'REQUEST_DOWNLOAD_CSV';
const DOWNLOAD_CSV_COMPLETED = 'DOWNLOAD_CSV_COMPLETED';

const SET_FOLLOWEDBY = 'SET_FOLLOWEDBY';

export default {
  REQUEST_SESSION_JSON,
  RECEIVE_SESSION_JSON,
  REQUEST_PATIENT_JSON,
  RECEIVE_PATIENT_JSON,
  REQUEST_DOWNLOAD_CSV,
  DOWNLOAD_CSV_COMPLETED,
  SET_FOLLOWEDBY
};
