import { connect } from 'react-redux';
import PatientsComponent from './PatientsComponent';
import { homeOperations, homeActions } from '../../home/redux';
import { onboardOperations, onboardActions } from '../redux';
import { loginOperations } from '../../auth/login/redux';

const mapStateToProps = state => {
  const { portalConfig } = state.login;
  return {
    homeState: state.home,
    onboardState: state.onboard,
    loginState: state.login,
    portalConfig
  };
};

const mapDispatchToProps = dispatch => {
  const getSessionData = (
    lastLogin,
    partnerList,
    sort,
    sortOrder,
    limit,
    offset,
    filters
  ) =>
    dispatch(
      homeOperations.getSessionJson(
        lastLogin,
        partnerList,
        sort,
        sortOrder,
        limit,
        offset,
        filters
      )
    );

  const getPatientsData = (
    partnerList,
    sort,
    sortOrder,
    limit,
    offset,
    filters
  ) =>
    dispatch(
      onboardOperations.getPatientsJson(
        partnerList,
        sort,
        sortOrder,
        limit,
        offset,
        filters
      )
    );
  const saveConfiguration = data =>
    dispatch(loginOperations.saveConfiguration(data));

  const setFollowedby = value => dispatch(homeActions.setFollowedby(value));
  const setTotalPatients = () => dispatch(onboardActions.setTotalPatients());

  return {
    getSessionData,
    getPatientsData,
    saveConfiguration,
    setFollowedby,
    setTotalPatients
  };
};

const PatientsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientsComponent);

export default PatientsContainer;
