import { connect } from 'react-redux';
import PswResetComponent from './PswResetComponent';
import { resetOperations } from './redux';
import { resetCheckEmail } from './redux/actions';

const mapStateToProps = state => {
  const {
    spinner,
    error,
    text,
    spinnerCode,
    codeerror,
    codetext,
    codeValidated,
    resetLink
  } = state.reset;
  return {
    spinner,
    error,
    text,
    spinnerCode,
    codeerror,
    codetext,
    codeValidated,
    resetLink
  };
};

const mapDispatchToProps = dispatch => {
  const checkPhoneNumber = data =>
    dispatch(resetOperations.checkPhoneNumber(data));
  const validateConfirmationCode = data =>
    dispatch(resetOperations.validateConfirmationCode(data));
  const reset = () => dispatch(resetCheckEmail());

  return { checkPhoneNumber, validateConfirmationCode, reset };
};

const PswResetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PswResetComponent);

export default PswResetContainer;
