import firebase from '../../../config/firebase';

const cache = [];
const func = firebase
  .functions()
  .httpsCallable('dashboardFunctions-getCustomSurveys');

export async function getCustomSurveys() {
  if (cache.surveys) return Promise.resolve(cache.surveys);
  const { data } = await func();
  cache.surveys = data;

  return data;
}
