import { connect } from 'react-redux';
import SessionsComponent from './SessionsComponent';
import { homeOperations, homeActions } from '../home/redux';
import { loginOperations } from '../auth/login/redux';
import { onboardOperations, onboardActions } from '../onboard/redux';

const mapStateToProps = state => {
  return {
    homeState: state.home,
    onboardState: state.onboard,
    loginState: state.login,
    portalConfig: state.login.portalConfig
  };
};

const mapDispatchToProps = dispatch => {
  const getSessionData = (
    lastLogin,
    partnerList,
    sort,
    sortOrder,
    limit,
    offset,
    filters
  ) =>
    dispatch(
      homeOperations.getSessionJson(
        lastLogin,
        partnerList,
        sort,
        sortOrder,
        limit,
        offset,
        filters
      )
    );

  const getPatientsData = (
    partnerList,
    sort,
    sortOrder,
    limit,
    offset,
    filters
  ) =>
    dispatch(
      onboardOperations.getPatientsJson(
        partnerList,
        sort,
        sortOrder,
        limit,
        offset,
        filters
      )
    );

  const saveFilter = data => dispatch(loginOperations.saveFilter(data));
  const saveLogs = data => dispatch(loginOperations.saveLogs(data));
  const downloadCsv = data => dispatch(homeOperations.downloadCsv(data));

  const setFollowedby = value => dispatch(homeActions.setFollowedby(value));
  const setTotalPatients = () => dispatch(onboardActions.setTotalPatients());

  return {
    getSessionData,
    getPatientsData,
    setFollowedby,
    setTotalPatients,
    saveFilter,
    saveLogs,
    downloadCsv
  };
};

const SessionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionsComponent);

export default SessionsContainer;
