import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '90%',
    margin: '40px 40px 40px 40px'
  }
});

function BottomDisclaimerPage({ classes }) {
  return (
    <div className={classes.root} id="disclaimer">
      <Typography style={{ fontSize: '12px' }} variant="body1">
        "The values provided are clinical support tools. Depression and Anxiety
        Symptom Assessments are a preliminary analysis, and not for diagnostic
        purposes. For definitive diagnosis, a clinical interview must be
        performed. Symptom Assessments are analyzed using acoustic and semantic
        characteristics of voice, which provide insights and are suggestive of
        depression and anxiety."{' '}
        <Link
          title="Disclaimer page"
          to={{
            pathname: `/disclaimer` // `/details/${valueForPath}`
          }}
        >
          Additional information is available here
        </Link>
      </Typography>
    </div>
  );
}

BottomDisclaimerPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(BottomDisclaimerPage);
