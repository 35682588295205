import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  cont: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: 4,
    flex: 1,
    alignItems: 'center'
  }
});

function Top({ classes, name, value }) {
  return (
    <div className={classes.cont}>
      <Typography style={{ width: '50%' }} color="inherit" variant="subtitle1">
        {name}
      </Typography>
      <div
        style={{
          height: '100%',
          alignItems: 'center',
          width: '50%'
        }}
      >
        <Typography
          style={{ fontWeight: 'bold' }}
          color="inherit"
          variant="subtitle1"
        >
          {value}
        </Typography>
      </div>
    </div>
  );
}

Top.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  classes: PropTypes.object
};

const enhance = withStyles(styles);

export default enhance(Top);
