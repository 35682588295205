/* 
    This file contains string literals for your action types. 
    This provides an easy reference for the actions available. 
    These strings are exported as an object literal which can then be 
    imported into your reducers and action creators instead of hard-coding them. 
    Although maintaining a separate file containing just your action types is 
    optional, it is highly recommended.
*/

const REQUEST_PATIENTS_JSON = 'REQUEST_PATIENTS_JSON';
const RECEIVE_PATIENTS_JSON = 'RECEIVE_PATIENTS_JSON';

const SET_TOTAL_PATIENTS = 'SET_TOTAL_PATIENTS';

export default {
  REQUEST_PATIENTS_JSON,
  RECEIVE_PATIENTS_JSON,
  SET_TOTAL_PATIENTS
};
