import types from './types';

const INITIAL_STATE = {
  sort: { createdAt: 'desc' },
  sortOrder: ['createdAt'],
  rowsPerPage: 10,
  offset: 0,
  showSpinner: false,
  patientsData: [],
  totalPatients: -1,
  patientsCount: -1,
  filters: []
};
const onboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUEST_PATIENTS_JSON: {
      return {
        ...state,
        showSpinner: true,
        patientsData: []
      };
    }

    case types.RECEIVE_PATIENTS_JSON: {
      const {
        patientsData,
        patientsCount,
        sort,
        sortOrder,
        rowsPerPage,
        offset,
        filters
      } = action;

      return {
        ...state,
        sort,
        sortOrder,
        rowsPerPage,
        offset,
        filters,
        patientsData,
        patientsCount,
        totalPatients:
          state.totalPatients === -1 || patientsCount > state.totalPatients
            ? patientsCount
            : state.totalPatients,
        showSpinner: false
      };
    }

    case types.SET_TOTAL_PATIENTS: {
      return {
        ...state,
        totalPatients: -1
      };
    }

    default:
      return state;
  }
};

export default onboardReducer;
